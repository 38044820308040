import * as Sentry from '@sentry/browser';

Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DNS, environment: 'production' });

export const notifySentry = (err: any, req?: any, statusCode?: number): void => {
  Sentry.configureScope((scope) => {
    if (!req) {
      scope.setTag('req', 'false');
    } else {
      scope.setExtra('url', req.url);
      scope.setExtra('params', req.params);
      scope.setExtra('query', req.query);
      scope.setExtra('statusCode', statusCode);
      scope.setExtra('headers', req.headers);

      if (req.user) {
        scope.setUser({ id: req.user.id, email: req.user.email });
      }
    }
  });

  Sentry.captureException(err);
};

export default Sentry;
