import { signOut } from '../utils/cookies';
import { useHistory } from 'react-router-dom';
import { useAuthDataContext } from './useAuthenticated';

export default function useLogout() {
  const history = useHistory();
  const authenticated = useAuthDataContext();
  async function signOutUser() {
    await signOut();
    // @ts-ignore
    authenticated.onLogout();
    history.push('/auth');
  }

  return {
    signOutUser,
  };
}
