import { useEffect } from 'react';
// @ts-ignore
import { withNavigationViewController } from '@atlaskit/navigation-next';

function SetActiveMenuBase({
  id,
  navigationViewController,
}: {
  id: string;
  navigationViewController: any;
}) {
  useEffect(() => {
    const { containerViewId, productViewId } = navigationViewController.state;
    if (id !== containerViewId && id !== productViewId) {
      navigationViewController.setView(id);
    }
  }, [id, navigationViewController]);

  return null;
}

const SetActiveMenu = withNavigationViewController(SetActiveMenuBase);

export default SetActiveMenu;
