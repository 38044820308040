import React from 'react';
// @ts-ignore
import { NavigationRendererItemType } from '@atlaskit/navigation-next';
import AppAccessIcon from '@atlaskit/icon/glyph/app-access';
import CheckboxIcon from '@atlaskit/icon/glyph/checkbox';
import MenuRegistrar from '../../MenuRegistrar';

const getItems = (): NavigationRendererItemType<{
  type: 'LinkItem';
  id: string;
}>[] => [
  {
    id: 'root/merchants/index:header',
    items: [
      {
        type: 'Wordmark',
        wordmark: () => <p>Merchants</p>,
        id: 'cth-wordmark',
      },
      {
        id: 'back-button',
        items: [
          {
            type: 'BackItem',
            goTo: 'root/index',
            id: 'back',
            text: 'Back to home',
          },
        ],
        type: 'Group',
      },
    ],
    type: 'HeaderSection',
  },
  {
    id: 'root/merchants/index:menu',
    nestedGroupKey: 'menu',
    items: [
      {
        before: AppAccessIcon,
        id: 'all-merchants',
        text: 'All Merchants',
        to: '/merchants',
        type: 'LinkItem',
      },
      {
        before: CheckboxIcon,
        id: 'pending-merchants',
        text: 'Pending Approval',
        to: '/merchants/pending',
        type: 'LinkItem',
      },
    ],
    type: 'MenuSection',
  },
];
const MerchantsView = () => (
  <MenuRegistrar getItemsFactory={() => getItems} type="container" viewId="root/merchants/index" />
);

const MerchantsMenu = () => <MerchantsView />;

export default MerchantsMenu;
