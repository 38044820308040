import CryptoJS from 'crypto-js';
import { isNil, isEmpty, pathOr } from 'ramda';
//@ts-ignore
import { ValidationState } from '@atlaskit/select';
// @ts-ignore
import { Money } from 'walletjs';
import { AddressType, Product, VariationType, UserToken, Coupon } from '../types';
import { Months } from './constants';
import { isAfter, isSameDay } from 'date-fns';

/**
 * Decrypt cypher texts
 * @param {string} cipherText - Encrypted code
 * @returns {Object} - Decrypted object
 */
const decrypt = (cipherText: string): UserToken => {
  const decrypted = CryptoJS.AES.decrypt(
    cipherText,
    process.env.REACT_APP_CRYPTO_KEY as string,
  ).toString(CryptoJS.enc.Utf8);
  return JSON.parse(decrypted);
};

/**
 * Encrypt data
 * @param {any} data - Data to be encrypted
 * @returns {string} - Cipher text
 */
const encrypt = (data: string): string => {
  const encrypted = CryptoJS.AES.encrypt(
    data,
    process.env.REACT_APP_CRYPTO_KEY as string,
  ).toString();

  return encrypted;
};

const itemHasValue = (item: any): boolean => !isNil(item) && !isEmpty(item);

export { encrypt, decrypt, itemHasValue };

export const capitalize: (a?: string) => string = (str = '') =>
  str.toLowerCase().replace(/^\w/, (t) => t.toUpperCase());

export const composeAddress = (address: AddressType, country: string): string => `${
  address.addressLineOne
},
  ${itemHasValue(address.addressLineTwo) ? `${address.addressLineTwo},` : ''}
  ${address.city}, ${itemHasValue(address.county) ? `${address.county},` : ''}
  ${country}.`;

/**
 * Convert number into currency format
 * @example convertProductPrice(19999) = 199.99
 * @example convertProductPrice(190999) = 1,909.99
 * @example convertProductPrice(10000500) = 100,005.00
 * @param {number} amount amount to convert
 * @returns string
 */
export const convertProductPrice = (amount: number): string => {
  const money = Money.init(amount);
  return money.getValue();
};

export const calculateStock = (variation: VariationType[]) => {
  let stock = 0;
  variation.forEach((i) => {
    stock += i.stock;
  });
  return stock;
};

const getMinMaxPrice = (type: 'min' | 'max', variations?: VariationType[]): number =>
  variations && variations.length > 0
    ? Math[type].apply(
        null,
        variations.map((item) => item.price),
      )
    : 0;

export const constructProductPrice = (product?: Product) => {
  if (product) {
    const hasVariation = pathOr([], ['variations'], product);

    if (itemHasValue(hasVariation)) {
      return `£${convertProductPrice(
        getMinMaxPrice('min', product.variations),
      )} - £${convertProductPrice(getMinMaxPrice('max', product.variations))}`;
    }
    return `£${convertProductPrice(product.price as number)}`;
  }

  return 'N/A';
};

export const validate = (value: any) => (!value ? 'EMPTY' : undefined);

export const getValidationState = (error: any, valid: boolean): ValidationState => {
  if (!error && !valid) {
    return 'default';
  }
  if (valid === true) {
    return 'success';
  }
  return 'error';
};

export const formatDate = (date: string) => {
  const currentYear = new Date(date).getFullYear();
  const currentMonth = new Date(date).getMonth();
  const currentDate = new Date(date).getDate();

  return `${currentDate}-${Months[currentMonth]}-${currentYear}`;
};

export const appendCurrency = (amt: number) => {
  return new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' })
    .format(amt)
    .slice(0, -3);
};

export const extractCouponStatus = (coupon: Coupon) => {
  const isExpired = isAfter(new Date(), coupon.expiry) || isSameDay(new Date(), coupon.expiry);
  return isExpired ? 'EXPIRED' : coupon.status;
};
