import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { RouteProps, RouteComponentProps } from 'react-router-dom';
import { useAuthDataContext } from '../hooks/useAuthenticated';
import { itemHasValue } from '../utils/utils';

interface UnAuthedRouteProps extends RouteProps {
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
}

function UnAuthedRouteProps(props: UnAuthedRouteProps) {
  const authenticated = useAuthDataContext();
  const { component: Children, ...rest } = props;
  if (authenticated && !itemHasValue(authenticated.token)) {
    return <Route {...rest} render={(props) => <Children {...props} />} />;
  }
  return (
    <Redirect
      to={{
        pathname: '/',
      }}
    />
  );
}

export default UnAuthedRouteProps;
