import Button from '@atlaskit/button';
// @ts-ignore
import Form, { Field, FormFooter, FormHeader } from '@atlaskit/form';
// @ts-ignore
import TextField from '@atlaskit/textfield';
import React, { Fragment } from 'react';
import styled from 'styled-components';
import GraphqlError from '../components/Error';
import useAuth from '../hooks/useAuth';

const AuthPageContainer = styled.div`
  display: flex;
  width: 400px;
  max-width: 100%;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const AuthPage = () => {
  const { handleFormSubmit, error, loading } = useAuth();

  return (
    <AuthPageContainer>
      <Form
        onSubmit={(data: any) => {
          handleFormSubmit(data.email, data.password);
        }}
      >
        {({ formProps, submitting }: any) => (
          <form {...formProps}>
            <FormHeader title="Login to your account" />
            <Field name="email" label="Email address" isRequired>
              {({ fieldProps }: any) => (
                <Fragment>
                  <TextField type="email" autoComplete="email" {...fieldProps} />
                </Fragment>
              )}
            </Field>
            <Field name="password" label="Password" isRequired>
              {({ fieldProps }: any) => (
                <Fragment>
                  <TextField type="password" {...fieldProps} />
                </Fragment>
              )}
            </Field>
            <GraphqlError error={error} />
            <FormFooter>
              <Button type="submit" appearance="primary" isLoading={loading || submitting}>
                Proceed
              </Button>
            </FormFooter>
          </form>
        )}
      </Form>
    </AuthPageContainer>
  );
};

export default AuthPage;
