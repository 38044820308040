import gql from 'graphql-tag';

export const GET_SALON_SERVICE = gql`
  query GetSalonServices(
    $where: SalonServiceWhereInput
    $orderBy: SalonServiceOrderByInput
    $first: Int
    $skip: Int
  ) {
    salonServices(where: $where, orderBy: $orderBy, first: $first, skip: $skip) {
      id
      title
      slug
    }
  }
`;

export const GET_SALONS = gql`
  query GetSalons($where: SalonWhereInput, $orderBy: SalonOrderByInput, $first: Int, $skip: Int) {
    salons(where: $where, orderBy: $orderBy, first: $first, skip: $skip) {
      id
      name
      slug
      about
      address {
        id
        country
      }
      email
      contact
      services {
        id
        title
        slug
      }
      images {
        id
        uri
      }
    }
  }
`;

export const GET_SALON = gql`
  query GetSalon($where: SalonWhereUniqueInput!) {
    salon(where: $where) {
      id
      name
      slug
      about
      email
      contact
      address {
        country
        city
        addressLineOne
        addressLineTwo
        county
        id
        postalCode
      }
      review {
        id
        star
        user {
          id
          firstName
          lastName
        }
        comment
        createdAt
      }
      map {
        id
        longitude
        latitude
      }
      openingHours {
        id
        day
        open
        close
      }
      services {
        id
        title
        slug
      }
      images {
        id
        uri
      }
    }
  }
`;

export const GET_SALON_REVIEWS = gql`
  query GetSalonReviews(
    $where: SalonReviewWhereInput
    $orderBy: SalonReviewOrderByInput
    $first: Int
    $skip: Int
  ) {
    salonReviews: adminSalonReviews(where: $where, orderBy: $orderBy, first: $first, skip: $skip) {
      id
      star
      user {
        id
        firstName
        lastName
        email
        image {
          id
          uri
        }
      }
      salon {
        id
        images {
          id
          uri
        }
        name
        slug
        id
        email
      }
      comment
    }
  }
`;

export const GET_TESTIMONIALS = gql`
  query GetTestimonials(
    $where: TestimonialWhereInput
    $orderBy: TestimonialOrderByInput
    $first: Int
    $skip: Int
  ) {
    testimonials(where: $where, orderBy: $orderBy, first: $first, skip: $skip) {
      id
      name
      description
      rating
      image {
        id
        uri
      }
    }
  }
`;
