import gql from 'graphql-tag';

export const GET_ALL_BUYERS = gql`
  query AllBuyers($where: BuyerWhereInput, $orderBy: BuyerOrderByInput, $first: Int, $skip: Int) {
    buyers(where: $where, orderBy: $orderBy, first: $first, skip: $skip) {
      id
      firstName
      lastName
      address {
        id
        country
      }
      email
      mobile
      provider
      image {
        id
        uri
      }
      createdAt
    }
  }
`;

export const GET_BUYER_PROFILE = gql`
  query BuyerProfile($id: ID!) {
    buyerProfile(id: $id) {
      id
      firstName
      lastName
      email
      mobile
      provider
      gender
      image {
        id
        uri
      }
      isBlocked
      dob
      address {
        country
        city
        addressLineOne
        addressLineTwo
        county
        id
        postalCode
      }
      createdAt
    }
    orders(where: { buyer: { id: $id } }, orderBy: createdAt_DESC) {
      id
      orderNumber
      merchant {
        id
        email
        business {
          id
          shopName
          displayImage {
            uri
            id
          }
        }
      }
      price {
        id
        total
      }
      items {
        id
        quantity
        product {
          id
          title
          productAttribute {
            id
            images {
              id
              uri
            }
          }
        }
      }
      status
      createdAt
    }
    productReviews(where: { buyer: { id: $id } }, orderBy: createdAt_DESC) {
      id
      comment
      itemRatingWeight
      postageRatingWeight
      product {
        id
        title
        slug
        productCode
        productAttribute {
          id
          images {
            id
            uri
          }
        }
      }
      image {
        id
        uri
      }
      videoLink
      createdAt
    }
    orderIssues(where: { buyer: { id: $id } }, orderBy: createdAt_DESC) {
      id
      topic
      description
      image {
        id
        uri
      }
      productName
      status
      createdAt
    }
    coupons: couponUsages(where: { order: { merchant: { id: $id } } }) {
      id
      order {
        id
        buyer {
          id
          firstName
          lastName
          email
          image {
            id
            uri
          }
        }
        orderNumber
        items {
          id
        }
        price {
          id
          total
        }
      }
    }
  }
`;
