import { Label } from '@atlaskit/field-base';
import AppAccessIcon from '@atlaskit/icon/glyph/app-access';
import DepartmentIcon from '@atlaskit/icon/glyph/department';
import PeopleIcon from '@atlaskit/icon/glyph/people';
import { Grid, GridColumn } from '@atlaskit/page';
//@ts-ignore
import Select from '@atlaskit/select';
import Spinner from '@atlaskit/spinner';
import { format } from 'date-fns';
import pathOr from 'ramda/es/pathOr';
import React, { Fragment, useMemo } from 'react';
import styled from 'styled-components';
import { SetActiveMenu } from '../../components/navigations';
import useDashboard from '../../hooks/useDashboard';
import { timeFrames } from '../../utils/constants';
import { BuyerTable, IssuesTable, MerchantTable, RevenueLineChart, ReviewTable } from './component';

const Card = styled.div`
  margin: 1rem;
  padding: 2rem;
  border: 0.7px solid #eee;
  border-radius: 5px;
  box-shadow: 2.5px 5px 25px #eee;
  background-color: #fff;
  overflow: hidden;
  overflow-x: auto;
  max-width: 100%;
`;
//@ts-ignore
const CenteredCard = styled(Card)`
  text-align: center;
`;

const DashboardCard = styled(Card)`
  display: flex;
  justify-content: space-between;

  span {
    color: #f16cc0 !important;
  }
`;

const HomePage = () => {
  const { data, loading, revenueData, timeFrame, setTimeFrame } = useDashboard();
  const revenue = useMemo(
    () =>
      revenueData?.adminRevenue.map((value) =>
        Object.assign({}, value, { date: format(value.date, 'DD MMM YYYY') }),
      ),
    [revenueData],
  );

  if (loading) return <Spinner size="large" />;

  return (
    <Fragment>
      <SetActiveMenu id="root/index" />
      <h1>Dashboard</h1>
      <Grid layout="fluid">
        <GridColumn>
          <DashboardCard>
            <div>
              <h4>Total Buyers</h4>
              <p>{pathOr(0, ['adminAnalyticsData', 'totalBuyers'], data)}</p>
            </div>
            <PeopleIcon label="buyers" />
          </DashboardCard>
        </GridColumn>
        <GridColumn>
          <DashboardCard>
            <div>
              <h4>Total Merchants</h4>
              <p>{pathOr(0, ['adminAnalyticsData', 'totalMerchants'], data)}</p>
            </div>
            <AppAccessIcon label="merchants" />
          </DashboardCard>
        </GridColumn>
        <GridColumn>
          <DashboardCard>
            <div>
              <h4>Total Orders</h4>
              <p>{pathOr(0, ['adminAnalyticsData', 'totalOrders'], data)}</p>
            </div>
            <DepartmentIcon label="orders" />
          </DashboardCard>
        </GridColumn>
      </Grid>

      <h3>Revenue</h3>
      <div style={{ margin: '0 1rem' }}>
        <Grid layout="fluid">
          <GridColumn medium={6} />
          <GridColumn medium={6}>
            <Label htmlFor="time-frame" label="Time Frame" />
            <Select
              id="time-frame"
              onChange={setTimeFrame}
              options={timeFrames}
              placeholder={timeFrame}
            />
          </GridColumn>
        </Grid>
        {revenueData && revenueData.adminRevenue.length > 0 ? (
          <RevenueLineChart data={revenue} />
        ) : (
          <CenteredCard>
            <strong> No revenue has been generated!</strong>
          </CenteredCard>
        )}
      </div>

      <h3>New Users</h3>
      <Grid layout="fluid">
        <GridColumn medium={12}>
          <Card>
            <h4>Buyers</h4>
            <BuyerTable data={data} />
          </Card>
        </GridColumn>
        <GridColumn medium={12}>
          <Card>
            <h4>Merchants</h4>
            <MerchantTable data={data} />
          </Card>
        </GridColumn>
      </Grid>

      {/* Issues & Reviews section */}

      <h3>Feedback</h3>
      <Grid layout="fluid">
        <GridColumn medium={12}>
          <Card>
            <h4>Reviews</h4>
            <ReviewTable data={data} />
          </Card>
        </GridColumn>
        <GridColumn medium={12}>
          <Card>
            <h4> Order Issues </h4>
            <IssuesTable data={data} />
          </Card>
        </GridColumn>
      </Grid>
    </Fragment>
  );
};

export default HomePage;
