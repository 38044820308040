import DepartmentIcon from '@atlaskit/icon/glyph/department';
import QuestionCircleIcon from '@atlaskit/icon/glyph/question-circle';
import CrossCircleIcon from '@atlaskit/icon/glyph/cross-circle';
import CheckCircleIcon from '@atlaskit/icon/glyph/check-circle';
import RecentIcon from '@atlaskit/icon/glyph/recent';
// @ts-ignore
import { NavigationRendererItemType } from '@atlaskit/navigation-next';
import React from 'react';
import MenuRegistrar from '../../MenuRegistrar';

const getItems = (): NavigationRendererItemType<{
  type: 'LinkItem';
  id: string;
}>[] => [
  {
    id: 'root/orders/index:header',
    items: [
      {
        type: 'Wordmark',
        wordmark: () => <p>Orders</p>,
        id: 'cth-wordmark',
      },
      {
        id: 'back-button',
        items: [
          {
            type: 'BackItem',
            goTo: 'root/index',
            id: 'back',
            text: 'Back to home',
          },
        ],
        type: 'Group',
      },
    ],
    type: 'HeaderSection',
  },
  {
    id: 'root/orders/index:menu',
    nestedGroupKey: 'menu',
    items: [
      {
        before: RecentIcon,
        id: 'pending-orders',
        text: 'Pending Orders',
        to: '/orders/pending',
        type: 'LinkItem',
      },
      {
        before: CheckCircleIcon,
        id: 'delivered-orders',
        text: 'Delivered Orders',
        to: '/orders/delivered',
        type: 'LinkItem',
      },
      {
        before: CrossCircleIcon,
        id: 'cancelled-orders',
        text: 'Cancelled Orders',
        to: '/orders/cancelled',
        type: 'LinkItem',
      },
      {
        before: DepartmentIcon,
        id: 'all-orders',
        text: 'All Orders',
        to: '/orders',
        type: 'LinkItem',
      },
      {
        before: QuestionCircleIcon,
        id: 'order-issues',
        text: 'Order Issues',
        to: '/orders/issues',
        type: 'LinkItem',
      },
    ],
    type: 'MenuSection',
  },
];

const OrderView = () => (
  <MenuRegistrar getItemsFactory={() => getItems} type="container" viewId="root/orders/index" />
);

const OrdersMenu = () => <OrderView />;

export default OrdersMenu;
