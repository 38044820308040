import React from 'react';
// @ts-ignore
import { NavigationRendererItemType } from '@atlaskit/navigation-next';
import ActivityIcon from '@atlaskit/icon/glyph/activity';
import CommentIcon from '@atlaskit/icon/glyph/comment';
import EditorSettingsIcon from '@atlaskit/icon/glyph/editor/settings';
import EditorDecisionIcon from '@atlaskit/icon/glyph/editor/decision';
import MenuRegistrar from '../../MenuRegistrar';

const getItems = (): NavigationRendererItemType<{
  type: 'LinkItem';
  id: string;
}>[] => [
  {
    id: 'root/products/index:header',
    items: [
      {
        type: 'Wordmark',
        wordmark: () => <p>Products</p>,
        id: 'cth-wordmark',
      },
      {
        id: 'back-button',
        items: [
          {
            type: 'BackItem',
            goTo: 'root/index',
            id: 'back',
            text: 'Back to home',
          },
        ],
        type: 'Group',
      },
    ],
    type: 'HeaderSection',
  },
  {
    id: 'root/products/index:menu',
    nestedGroupKey: 'menu',
    items: [
      {
        before: ActivityIcon,
        id: 'all-products',
        text: 'All Products',
        to: '/products',
        type: 'LinkItem',
      },
      {
        before: CommentIcon,
        id: 'products-reviews',
        text: 'Reviews',
        to: '/products/reviews',
        type: 'LinkItem',
      },
      {
        before: EditorDecisionIcon,
        id: 'products-categories',
        text: 'Categories',
        to: '/products/categories',
        type: 'LinkItem',
      },
      {
        before: EditorSettingsIcon,
        id: 'products-hair-types',
        text: 'Hair Types',
        to: '/products/hair-types',
        type: 'LinkItem',
      },
    ],
    type: 'MenuSection',
  },
];

const ProductsView = () => (
  <MenuRegistrar getItemsFactory={() => getItems} type="container" viewId="root/products/index" />
);

const ProductsMenu = () => <ProductsView />;

export default ProductsMenu;
