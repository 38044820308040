import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import ErrorBoundaryImg from '../assets/error.png';
const Container = styled.div`
  height: 90vh;
  width: 100%;
  display: flex;
  align-items: center;
`;

const ErrorImageOverlay = styled.div`
  height: 50vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 20px;
`;

const ErrorImageContainer = styled.div<{ imageUrl: string }>`
  display: inline-block;
  background-image: ${(props): string => `url(${props.imageUrl})`};
  background-size: cover;
  background-position: center;
  width: 15vh;
  height: 15vh;
`;

const ErrorImageText = styled.h2`
  font-size: 1.5rem;
  color: #333;
  text-align: center;
`;

export default function ErrorPage({ message }: { message: string }) {
  return (
    <Container>
      <ErrorImageOverlay>
        <ErrorImageContainer imageUrl={ErrorBoundaryImg} />
        <ErrorImageText>
          {message}. Not to worry! <Link to="/">Click here</Link> to back to the home page
        </ErrorImageText>
      </ErrorImageOverlay>
    </Container>
  );
}
