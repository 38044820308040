import styled from 'styled-components';

const TableCell = styled.div`
  padding: 5px 10px;
  display: inline-flex;
`;

const TableCellCompact = styled.div`
  padding: 15px 15px;
`;
export { TableCellCompact };
export default TableCell;
