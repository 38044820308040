import { notifySentry } from './sentry';
import { ApolloClient } from 'apollo-boost';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { withClientState } from 'apollo-link-state';
import { onError } from 'apollo-link-error';
import { createUploadLink } from 'apollo-upload-client';
import { ApolloLink, Observable, Operation } from 'apollo-link';
import { getUserTokenFromCookie, getUserToken, signOut } from './utils/cookies';

const cache = new InMemoryCache({
  cacheRedirects: {
    Query: {},
  },
});

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_SERVER_URI,
});

const request = async (operation: Operation): Promise<any> => {
  const uerToken = getUserToken();
  operation.setContext({
    headers: {
      authorization: uerToken ? `Bearer ${getUserTokenFromCookie(uerToken).token}` : '',
    },
  });
};

const requestLink = new ApolloLink(
  (operation, forward): any =>
    new Observable((observer: any): any => {
      let handle: any;
      Promise.resolve(operation)
        .then((oper) => request(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        })
        .catch(observer.error.bind(observer));

      return (): void => {
        if (handle) handle.unsubscribe();
      };
    }),
);

const handleError = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      if (/not authorised/i.test(message)) {
        signOut();
        window.location.reload();
      }
      notifySentry(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
    });
  }
  if (networkError) {
    // TODO: Check for error type for logout activity response
    console.info(`[Network error]: ${networkError}`);
    notifySentry(networkError);
  }
});

// @ts-ignore
const stateLink = withClientState({
  cache,
  defaults: {},
});

const apolloClient = new ApolloClient({
  connectToDevTools: true,
  link: ApolloLink.from([handleError, requestLink, stateLink, httpLink]),
  cache,
});

export default apolloClient;
