import gql from 'graphql-tag';

export const GET_ALL_PRODUCTS = gql`
  query GetAllProducts(
    $where: ProductWhereInput
    $orderBy: ProductOrderByInput
    $first: Int
    $skip: Int
  ) {
    adminMerchantProducts(where: $where, orderBy: $orderBy, first: $first, skip: $skip) {
      id
      title
      slug
      productCode
      productAttribute {
        id
        lot
        images {
          id
          uri
        }
        closure {
          id
          parting
          length
        }
        hairType {
          id
          title
        }
      }
      stock
      colour
      price
      length
      variations {
        id
        colour
        length
        stock
        price
      }
      owner {
        id
        firstName
        lastName
        business {
          id
          displayImage {
            id
            uri
          }
          shopName
          slug
        }
      }
      averageReviews
      reviewsCount
      reviews {
        id
      }
      orders {
        id
      }
    }
  }
`;

export const GET_PRODUCT = gql`
  query GetProduct($where: ProductWhereUniqueInput) {
    product: adminProduct(where: $where) {
      id
      length
      title
      slug
      price
      productCode
      productAttribute {
        id
        closure {
          id
          parting
          length
        }
        description
        hairType {
          id
          title
        }
        images {
          id
          uri
        }
      }
      orders(orderBy: createdAt_DESC) {
        id
        quantity
        order {
          id
          createdAt
          buyer {
            id
            email
            image {
              id
              uri
            }
            firstName
            lastName
          }
          status
        }
      }
      owner {
        id
        business {
          id
          displayImage {
            id
            uri
          }
          shopName
        }
      }
      reviews {
        id
        buyer {
          id
          firstName
          lastName
          image {
            id
            uri
          }
        }
        comment
        createdAt
        image {
          id
          uri
        }
        product {
          id
          slug
          title
          productCode
          productAttribute {
            id
            images {
              id
              uri
            }
          }
        }
        videoLink
      }
      variations {
        id
        colour
        stock
        title
        slug
        productAttribute {
          id
          images {
            id
            uri
          }
        }
        price
        length
      }
    }
  }
`;

export const GET_PRODUCTS_REVIEWS = gql`
  query GetProductReviews(
    $where: ProductReviewWhereInput
    $orderBy: ProductReviewOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    productReviews(
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      id
      buyer {
        id
        email
        firstName
        lastName
        image {
          id
          uri
        }
      }
      comment
      product {
        id
        productCode
        productAttribute {
          id
          images {
            id
            uri
          }
        }
        slug
        title
      }
    }
  }
`;
