import CommentIcon from '@atlaskit/icon/glyph/comment';
import QuoteIcon from '@atlaskit/icon/glyph/quote';
// @ts-ignore
import { NavigationRendererItemType } from '@atlaskit/navigation-next';
import React from 'react';
import MenuRegistrar from '../../MenuRegistrar';

const getItems = (): NavigationRendererItemType<{
  type: 'LinkItem';
  id: string;
}>[] => [
  {
    id: 'root/reviews/index:header',
    items: [
      {
        type: 'Wordmark',
        wordmark: () => <p>Reviews</p>,
        id: 'cth-wordmark',
      },
      {
        id: 'back-button',
        items: [
          {
            type: 'BackItem',
            goTo: 'root/index',
            id: 'back',
            text: 'Back to home',
          },
        ],
        type: 'Group',
      },
    ],
    type: 'HeaderSection',
  },
  {
    id: 'root/reviews/index:menu',
    nestedGroupKey: 'menu',
    items: [
      {
        before: CommentIcon,
        id: 'products-reviews',
        text: 'Product Reviews',
        to: '/products/reviews',
        type: 'LinkItem',
      },
      {
        before: QuoteIcon,
        id: 'salon-reviews',
        text: 'Salon Reviews',
        to: '/salons/reviews',
        type: 'LinkItem',
      },
    ],
    type: 'MenuSection',
  },
];

const ReviewsView = () => (
  <MenuRegistrar getItemsFactory={() => getItems} type="container" viewId="root/reviews/index" />
);

const ReviewsMenu = () => <ReviewsView />;

export default ReviewsMenu;
