import gql from 'graphql-tag';

export const GET_ALL_MERCHANTS = gql`
  query AllMerchants(
    $where: MerchantWhereInput
    $orderBy: MerchantOrderByInput
    $first: Int
    $skip: Int
  ) {
    merchants(where: $where, orderBy: $orderBy, first: $first, skip: $skip) {
      id
      firstName
      lastName
      address {
        id
        country
      }
      email
      products {
        id
      }
      orders {
        id
      }
      isBlocked
      isApproved
      business {
        id
        name
        shopName
        displayImage {
          uri
          id
        }
        description
      }
      createdAt
    }
  }
`;

export const GET_ALL_PENDING_MERCHANTS = gql`
  query AllPendingMerchants(
    $where: MerchantWhereInput
    $orderBy: MerchantOrderByInput
    $first: Int
    $skip: Int
  ) {
    merchants(where: $where, orderBy: $orderBy, first: $first, skip: $skip) {
      id
      firstName
      lastName
      address {
        id
        country
      }
      email
      isConfirmed
      isBlocked
      isApproved
      business {
        id
        name
        shopName
        displayImage {
          uri
          id
        }
        description
      }
      createdAt
    }
  }
`;

export const GET_MERCHANT_PROFILE = gql`
  query GetMerchantProfile($id: ID!) {
    merchantProfile(where: { id: $id }) {
      id
      firstName
      lastName
      email
      gender
      dob
      isApproved
      isBlocked
      isConfirmed
      address {
        country
        city
        addressLineOne
        addressLineTwo
        county
        id
        postalCode
      }
      business {
        id
        name
        shopName
        displayImage {
          uri
          id
        }
        address {
          country
          city
          addressLineOne
          addressLineTwo
          county
          id
          postalCode
        }
        description
      }
      createdAt
    }
    orders(where: { merchant: { id: $id } }, orderBy: createdAt_DESC) {
      id
      orderNumber
      buyer {
        id
        email
        firstName
        lastName
        image {
          uri
          id
        }
      }
      price {
        id
        total
      }
      items {
        id
        quantity
        product {
          id
          title
          productAttribute {
            id
            images {
              id
              uri
            }
          }
        }
      }
      status
      createdAt
    }
    orderIssues(where: { order: { merchant: { id: $id } } }, orderBy: createdAt_DESC) {
      id
      topic
      buyer {
        id
        email
        firstName
        lastName
        image {
          uri
          id
        }
      }
      createdAt
      description
      image {
        id
        uri
      }
      productName
      status
    }
    productReviews(where: { product: { owner: { id: $id } } }, orderBy: createdAt_DESC) {
      id
      comment
      itemRatingWeight
      postageRatingWeight
      product {
        id
        title
        slug
        productCode
        productAttribute {
          id
          images {
            id
            uri
          }
        }
      }
      image {
        id
        uri
      }
      buyer {
        id
        firstName
        lastName
        image {
          uri
          id
        }
      }
      videoLink
    }
    adminMerchantProducts(where: { owner: { id: $id }, isParent: true }) {
      id
      title
      productCode
      stock
      colour
      price
      length
      isParent
      productAttribute {
        id
        density
        hairType {
          id
          title
        }
        images {
          id
          uri
        }
        lot
      }
      orders {
        id
      }
      slug
      variations {
        id
        colour
        length
        price
        productCode
        stock
      }
    }
    coupons: couponUsages(where: { order: { merchant: { id: $id } } }) {
      id
      order {
        id
        buyer {
          id
          firstName
          lastName
          email
          image {
            id
            uri
          }
        }
        orderNumber
        items {
          id
        }
        price {
          id
          total
        }
      }
    }
  }
`;
