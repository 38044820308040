import { useLazyQuery } from '@apollo/react-hooks';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SEARCH_DATA } from '../resolvers/queries';
import { AdminSearchResponse } from '../types';

const useGlobalNav = () => {
  const [searchInput, setSearchInput] = useState('');
  const history = useHistory();
  const viewProfile = () => history.push(`/settings`);

  const [handleSearch, { data, error, loading }] = useLazyQuery<AdminSearchResponse>(SEARCH_DATA, {
    fetchPolicy: 'cache-and-network',
  });

  const handleSearchInput = async ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = target;
    setSearchInput(value.trim());
  };

  const search = () => {
    const isSearchValueValid = /\w+/gi.test(searchInput) && searchInput.length >= 1;
    if (isSearchValueValid) {
      return handleSearch({
        variables: {
          transformedSearch: searchInput.toLowerCase(),
          searchTerm: searchInput,
        },
      });
    }
  };

  return {
    data,
    error,
    loading,
    handleSearchInput,
    search,
    searchInput,
    setSearchInput,
    viewProfile,
  };
};
export default useGlobalNav;
