import React from 'react';
// @ts-ignore
import { ConnectedItem } from '@atlaskit/navigation-next';
import { Link, Route } from 'react-router-dom';

const LinkItem = ({ itemComponent: Component = ConnectedItem, to, ...props }: any) => {
  return (
    <Route
      render={({ location: { pathname } }) => (
        <Component
          component={({ children, className, innerRef, dataset, draggableProps }: any) => (
            <Link
              className={className}
              to={to}
              onClick={props.onClick}
              innerRef={innerRef}
              {...dataset}
              {...draggableProps}
            >
              {children}
            </Link>
          )}
          isSelected={pathname === to}
          {...props}
        />
      )}
    />
  );
};

export default LinkItem;
