import Avatar from '@atlaskit/avatar';
// @ts-ignore
import Drawer, { DrawerItem, DrawerSkeletonItem } from '@atlaskit/drawer';
import EditorSettingsIcon from '@atlaskit/icon/glyph/editor/settings';
import SearchIcon from '@atlaskit/icon/glyph/search';
import SignOutIcon from '@atlaskit/icon/glyph/sign-out';
// @ts-ignore
import { GlobalNav } from '@atlaskit/navigation-next';
import Tabs, { TabItem } from '@atlaskit/tabs';
import { TabContentComponentProvided } from '@atlaskit/tabs/dist/cjs/types';
import Textfield from '@atlaskit/textfield';
import { pathOr } from 'ramda';
import React, { Fragment, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as CTHLogo } from '../../assets/cth-logo.svg';
import useGlobalNav from '../../hooks/useGlobalNav';
import useLogout from '../../hooks/useLogout';
import { ImageType } from '../../types';
import { capitalize } from '../../utils/utils';

const TabWrapper = styled.div`
  padding: 5px 10px;
  padding-bottom: 0;
  display: flex;
  align-items: center;
`;
const Counter = styled.span`
  padding: 3px 8px;
  background-color: lightpink;
  color: #fff;
  border-radius: 50%;
  text-align: center;
`;
const SearchFieldBox = styled.div`
  display: flex;
  max-width: 90%;
  padding: 5px;
  margin-bottom: 20px;
  border-bottom: 2px solid lightpink;
  & input[type='search']::-webkit-search-cancel-button {
    /* Remove default */
    -webkit-appearance: none;
  }
`;
const SearchButton = styled.button`
  background: transparent;
  outline: none;
  border: none;
  font-size: 30px;
  cursor: pointer;
`;
const SearchListings = styled.div`
  padding: 20px 0;

  & .search-item {
    border-radius: 15px;
    margin-top: 10px;
  }
`;

export const GlobalLink = ({ className, to, onClick, children }: any) => {
  return (
    <Link className={className} to={to} onClick={onClick}>
      {children}
    </Link>
  );
};

export const SearchDrawer = ({ children, isOpen, onClose }: any) => (
  <Drawer width="wide" onClose={onClose} isOpen={isOpen} shouldUnmountOnExit={false}>
    {children}
  </Drawer>
);

const globalNavPrimaryItems = ({ onSearchClick }: any) => [
  {
    id: 'cth',
    icon: () => <CTHLogo />,
    label: 'CTH',
    to: '/',
    component: GlobalLink,
  },
  { id: 'search', icon: SearchIcon, onClick: onSearchClick },
];

const globalNavSecondaryItems = ({
  handleLogout,
  viewSettings,
}: {
  handleLogout: () => any;
  viewSettings: () => any;
}) => [
  {
    icon: () => <EditorSettingsIcon label="settings" size="large" />,
    label: 'Settings',
    size: 'small',
    id: 'settings',
    onClick: viewSettings,
  },
  {
    icon: () => <SignOutIcon label="sign-out" />,
    label: 'Profile',
    size: 'small',
    id: 'profile',
    onClick: handleLogout,
  },
];

function GlobalNavigation() {
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const { signOutUser } = useLogout();
  const {
    data,
    loading,
    error,
    handleSearchInput,
    search,
    searchInput,
    setSearchInput,
    viewProfile,
  } = useGlobalNav();

  const toggleSearchDrawer = () => {
    setOpen(!open);
  };

  const navigate = (url: string) => {
    history.push(url);
    toggleSearchDrawer();
  };

  const getItemProps = (item: any, type: string): any => {
    const productProps = {
      title: capitalize(item.title),
      description: item.productCode,
      onClick: () => navigate(`/products/product-${item.id}`),
      elemBefore: ((
        <Avatar
          src={
            pathOr<ImageType[]>(
              require('../../assets/product-placeholder.png'),
              ['productAttribute', 'images'],
              item,
            )[0].uri
          }
        />
      ) as unknown) as Node,
    };
    const merchantProps = {
      title: capitalize(pathOr('', ['business', 'shopName'], item)),
      description: `${item?.firstName} ${item?.lastName}`,
      onClick: () => navigate(`/merchants/profile/${item.id}`),
      elemBefore: ((
        <Avatar
          src={pathOr(
            require('../../assets/product-placeholder.png'),
            ['business', 'displayImage', 'uri'],
            item,
          )}
        />
      ) as unknown) as Node,
    };
    const buyerProps = {
      title: capitalize(`${item.firstName} ${item.lastName}`),
      description: item.email,
      onClick: () => navigate(`/buyers/${item.id}`),
      elemBefore: ((
        <Avatar
          src={pathOr(require('../../assets/product-placeholder.png'), ['image', 'uri'], item)}
        />
      ) as unknown) as Node,
    };
    const salonProps = {
      title: capitalize(item.name),
      onClick: () =>
        navigate(`${String(process.env.REACT_APP_FRONTEND_APP_URI)}/salon/${item.slug}`),
      elemBefore: ((
        <Avatar
          src={pathOr(require('../../assets/product-placeholder.png'), ['uri'], item.images?.[0])}
        />
      ) as unknown) as Node,
    };

    switch (type.toLowerCase()) {
      case 'products':
        return productProps;
      case 'merchants':
        return merchantProps;
      case 'buyers':
        return buyerProps;
      case 'salons':
        return salonProps;
      default:
        return;
    }
  };

  const TabHead = (props: TabContentComponentProvided) => {
    const { totalResult } = props.data;

    return (
      <TabWrapper>
        <TabItem {...props} />
        {totalResult > 0 ? <Counter> {totalResult}</Counter> : ''}
      </TabWrapper>
    );
  };

  const TabInfo = ({ data, elementProps }: TabContentComponentProvided) => {
    const { label = '', totalResult, results } = data;
    const itemType = label.toLowerCase().split('-')[0].trim();

    return (
      <>
        <h4>{`${totalResult} ${label} Found!`}</h4>
        <SearchListings>
          {results?.map((result: any) => {
            const { title, ...otherItemProps } = getItemProps(result, itemType);
            return (
              <div className="search-item">
                <DrawerItem {...otherItemProps} {...elementProps}>
                  {title}
                </DrawerItem>
              </div>
            );
          })}
        </SearchListings>
      </>
    );
  };

  const renderSearchResults = () => {
    if (!searchInput) {
      return null;
    }
    if (data && !error) {
      const { buyers, merchants, products, salons } = data;
      return (
        <Tabs
          components={{
            Content: TabInfo,
            Item: TabHead,
          }}
          tabs={[
            {
              label: `Products`,
              totalResult: (products ?? []).length,
              results: products,
            },
            {
              label: 'Merchants',
              totalResult: (merchants ?? []).length,
              results: merchants,
            },
            {
              label: `Buyers`,
              totalResult: (buyers ?? []).length,
              results: buyers,
            },
            {
              label: 'Salons',
              totalResult: (salons ?? []).length,
              results: salons,
            },
          ]}
        />
      );
    }
  };

  useEffect(() => {
    const handleKeyDown = ({ key }: any) => {
      if (key === '/') {
        setOpen(!open);
      }
      if (key === 'Enter') {
        search();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [open, search]);
  return (
    <Fragment>
      <GlobalNav
        primaryItems={globalNavPrimaryItems({
          onSearchClick: toggleSearchDrawer,
        })}
        secondaryItems={globalNavSecondaryItems({
          handleLogout: signOutUser,
          viewSettings: viewProfile,
        })}
      />
      <SearchDrawer onClose={toggleSearchDrawer} isOpen={open}>
        <SearchFieldBox>
          <Textfield
            width="80%"
            appearance="none"
            name="searchInput"
            placeholder="Press enter to search.."
            type="search"
            value={searchInput}
            onChange={handleSearchInput}
          />
          {searchInput && (
            <SearchButton type="button" onClick={() => setSearchInput('')}>
              &times;
            </SearchButton>
          )}
        </SearchFieldBox>
        {loading ? (
          <>
            <DrawerSkeletonItem itemTextWidth="20rem" />
            <DrawerSkeletonItem itemTextWidth="20rem" />
            <DrawerSkeletonItem itemTextWidth="20rem" />
            <DrawerSkeletonItem itemTextWidth="20rem" />
            <DrawerSkeletonItem itemTextWidth="20rem" />
            <DrawerSkeletonItem itemTextWidth="20rem" />
          </>
        ) : (
          renderSearchResults()
        )}
      </SearchDrawer>
    </Fragment>
  );
}

export default GlobalNavigation;
