import Cookies from 'js-cookie';
import axios from 'axios';
import { encrypt, decrypt } from './utils';
import { UserToken } from '../types';

const banToken = (token: string): void => {
  axios.post(`${process.env.REACT_APP_SERVER_URI}signout`, null, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const getUserTokenFromCookie = (token: string): UserToken => decrypt(token);
const storeUserTokenInCookie = (token: string) => {
  const encryptedToken = encrypt(token);
  Cookies.set('CTH_token', encryptedToken);
};

export function getUserToken() {
  return Cookies.get('CTH_token');
}

const clearCookies = (...keys: string[]): void => {
  keys.forEach((key) => {
    Cookies.remove(key);
  });
};

export const signOut = (): void => {
  const userToken = getUserToken();
  if (userToken) {
    const { token } = getUserTokenFromCookie(userToken);
    clearCookies('CTH_token');
    banToken(token);
  }
};

export { storeUserTokenInCookie, getUserTokenFromCookie };
