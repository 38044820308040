import React, { createContext, useState, useEffect, useMemo, useContext } from 'react';
import { getUserToken, getUserTokenFromCookie } from '../utils/cookies';
import { UserToken } from '../types';

const isAuthenticated = () => {
  const token = getUserToken();
  if (token) {
    const userToken = getUserTokenFromCookie(token);
    return userToken;
  }
  return {};
};

interface ContextType extends UserToken {
  onLogout: Function;
  onLogin: Function;
}

export const AuthDataContext = createContext<ContextType | null>(null);

const initialAuthData = {};

const AuthDataProvider = (props: any) => {
  const [authData, setAuthData] = useState(isAuthenticated());

  useEffect(() => {
    const currentAuthData = isAuthenticated();
    if (currentAuthData) {
      setAuthData(currentAuthData);
    }
  }, []);

  const onLogout = () => setAuthData(initialAuthData);

  const onLogin = (newAuthData: UserToken) => setAuthData(newAuthData);

  const authDataValue = useMemo(() => ({ ...authData, onLogout, onLogin }), [authData]);

  return <AuthDataContext.Provider value={authDataValue} {...props} />;
};

export const useAuthDataContext = () => useContext(AuthDataContext);

export default AuthDataProvider;
