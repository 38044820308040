import gql from 'graphql-tag';

export * from './salon';
export * from './buyer';
export * from './merchant';
export * from './product';
export * from './order';

export const GET_TESTIMONIAL = gql`
  query GetTestimonial($id: ID) {
    testimonial(id: $id) {
      id
      name
      description
      rating
      image {
        id
        uri
      }
    }
  }
`;

export const GET_HAIR_TYPES = gql`
  query GetHairTypes(
    $where: HairTypeWhereInput
    $orderBy: HairTypeOrderByInput
    $first: Int
    $skip: Int
  ) {
    hairTypes(where: $where, orderBy: $orderBy, first: $first, skip: $skip) {
      id
      title
      slug
      createdAt
    }
  }
`;

export const GET_HAIR_TYPE = gql`
  query GetHairType($id: ID) {
    hairType(id: $id) {
      id
      title
      slug
      createdAt
    }
  }
`;

export const GET_COUPONS = gql`
  query GetCoupons($where: CouponWhereInput) {
    allCoupons: coupons(where: $where, orderBy: createdAt_DESC) {
      id
      code
      discount
      status
      type
      merchant {
        id
        business {
          id
          shopName
        }
      }
      product {
        id
        title
      }
      expiry
    }
  }
`;

export const GET_COUPON = gql`
  query GetCoupon($id: ID) {
    coupon(id: $id) {
      id
      code
      discount
      status
      type
      merchant {
        id
        business {
          shopName
        }
        firstName
        lastName
      }
      product {
        id
        productCode
        title
      }
      expiry
      usage {
        id
        order {
          id
          buyer {
            id
            firstName
            lastName
            image {
              id
              uri
            }
          }
          items {
            id
            quantity
            price
            product {
              id
              title
            }
          }
          orderNumber
          price {
            id
            total
          }
        }
      }
    }
  }
`;

export const GET_CATEGORY_TYPES = gql`
  query GetCategoryTypes(
    $where: CategoryTypeWhereInput
    $orderBy: CategoryTypeOrderByInput
    $first: Int
    $skip: Int
  ) {
    categoryTypes(where: $where, orderBy: $orderBy, first: $first, skip: $skip) {
      id
      parent {
        id
        title
      }
      category {
        id
        title
        slug
        shortCode
      }
      children {
        id
        title
        slug
        shortCode
      }
      isMultiple
    }
  }
`;

export const GET_CATEGORY_TYPE = gql`
  query GetCategoryType($id: ID) {
    categoryType(id: $id) {
      id
      parent {
        id
        title
      }
      category {
        id
        title
        slug
        shortCode
      }
      children {
        id
        title
        slug
        shortCode
      }
      isMultiple
    }
  }
`;

export const GET_CATEGORIES = gql`
  query GetCategories(
    $where: CategoryWhereInput
    $orderBy: CategoryOrderByInput
    $first: Int
    $skip: Int
  ) {
    categories(where: $where, orderBy: $orderBy, first: $first, skip: $skip) {
      id
      title
      slug
      shortCode
    }
  }
`;

export const GET_CATEGORY_GROUP_WITH_CATEGORY = gql`
  query GetCategoryGroupWithCategory {
    categoryGroups {
      id
      title
    }
    categories {
      id
      title
      slug
      shortCode
    }
  }
`;

export const SEARCH_DATA = gql`
  query SearchData($searchTerm: String, $transformedSearch: String) {
    merchants(
      where: {
        OR: [
          { business: { name: $searchTerm } }
          { business: { shopName_contains: $searchTerm } }
          { business: { searchableName_contains: $transformedSearch } }
          { business: { searchableShopName_contains: $transformedSearch } }
        ]
      }
    ) {
      id
      firstName
      lastName
      business {
        id
        displayImage {
          id
          uri
        }
        shopName
      }
    }
    products(
      where: { OR: [{ searchableTitle_contains: $transformedSearch }] }
      orderBy: searchableTitle_ASC
    ) {
      id
      productCode
      productAttribute {
        id
        images {
          id
          uri
        }
      }
      title
    }
    buyers(
      where: {
        OR: [
          { firstName_contains: $searchTerm }
          { lastName_contains: $searchTerm }
          { searchableFirstName_contains: $transformedSearch }
          { searchableLastName_contains: $transformedSearch }
        ]
      }
      orderBy: firstName_ASC
    ) {
      id
      email
      firstName
      lastName
    }
    salons(
      where: {
        OR: [{ searchableName_contains: $transformedSearch }, { name_contains: $transformedSearch }]
      }
      orderBy: name_ASC
    ) {
      id
      images {
        id
        uri
      }
      name
      slug
    }
  }
`;

export const GET_DASHBOARD_DATA = gql`
  query DashboardData {
    adminAnalyticsData {
      totalMerchants: merchants
      totalBuyers: buyers
      totalOrders: orders
      totalProducts: products
    }
    merchants(orderBy: createdAt_DESC, first: 5) {
      id
      business {
        id
        email
        description
        displayImage {
          id
          uri
        }
        shopName
      }
    }

    buyers(orderBy: createdAt_DESC, first: 5) {
      id
      firstName
      lastName
      email
      image {
        id
        uri
      }
      provider
    }

    orderIssues(orderBy: createdAt_DESC, first: 5) {
      id
      topic
      buyer {
        id
        image {
          id
          uri
        }
        email
        firstName
        lastName
      }
      productName
    }
    productReviews(orderBy: createdAt_DESC, first: 5) {
      id
      buyer {
        id
        email
        firstName
        lastName
        image {
          id
          uri
        }
      }
      comment
      product {
        id
        productAttribute {
          id
          images {
            id
            uri
          }
        }
        productCode
        slug
        title
      }
    }
  }
`;

export const GET_ADMIN_REVENUE = gql`
  query AdminRevenue($filterBy: AdminRevenueFilter) {
    adminRevenue(filterBy: $filterBy) {
      date
      revenue
    }
  }
`;
