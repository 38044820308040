import gql from 'graphql-tag';

export const SIGN_IN = gql`
  mutation adminSignIn($email: String!, $password: String!) {
    adminSignIn(email: $email, password: $password) {
      token
      admin {
        email
        id
        name
      }
    }
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation adminSignIn($name: String, $currentPassword: String, $newPassword: String) {
    adminAccountDetailsUpdate(
      name: $name
      currentPassword: $currentPassword
      newPassword: $newPassword
    ) {
      id
    }
  }
`;

export const CREATE_SALON_SERVICE = gql`
  mutation createSalonService($title: String) {
    createSalonService(title: $title) {
      id
    }
  }
`;

export const DELETE_SALON_SERVICE = gql`
  mutation deleteSalonService($id: ID) {
    deleteSalonService(id: $id) {
      id
    }
  }
`;

export const CREATE_SALON = gql`
  mutation createSalon(
    $name: String
    $about: String
    $map: SalonCordinateCreateInput
    $address: AddressInput
    $email: String
    $contact: String
    $website: String
    $services: [SalonServiceWhereUniqueInput!]
    $openingHours: [SalonOpeningHourCreateInput!]
    $images: ImageCreateManyInput
  ) {
    createSalon(
      name: $name
      about: $about
      map: $map
      address: $address
      email: $email
      contact: $contact
      website: $website
      services: $services
      openingHours: $openingHours
      images: $images
    ) {
      id
    }
  }
`;

export const UPDATE_SALON = gql`
  mutation updateSalon($id: ID, $data: SalonUpdateInput!) {
    updateSalon(id: $id, data: $data) {
      id
      slug
    }
  }
`;

export const DELETE_SALON = gql`
  mutation deleteSalon($id: ID) {
    deleteSalon(id: $id) {
      id
    }
  }
`;

export const DELETE_SALON_REVIEW = gql`
  mutation deleteSalonReview($title: String) {
    deleteSalonReview(title: $title) {
      id
    }
  }
`;

export const CREATE_TESTIMONIAL = gql`
  mutation createTestimonial(
    $name: String
    $description: String
    $rating: Int
    $image: ImageCreateOneInput
  ) {
    createTestimonial(name: $name, description: $description, rating: $rating, image: $image) {
      id
    }
  }
`;
export const UPDATE_TESTIMONIAL = gql`
  mutation updateTestimonial(
    $id: ID
    $name: String
    $description: String
    $rating: Int
    $image: ImageCreateOneInput
  ) {
    updateTestimonial(
      id: $id
      name: $name
      description: $description
      rating: $rating
      image: $image
    ) {
      id
    }
  }
`;

export const DELETE_TESTIMONIAL = gql`
  mutation deleteTestimonial($id: ID) {
    deleteTestimonial(id: $id) {
      id
    }
  }
`;

export const CONFIRM_MERCHANT_ACCOUNT = gql`
  mutation adminConfirmMerchantAccount($id: String) {
    adminConfirmMerchantAccount(id: $id) {
      id
    }
  }
`;

export const REVOKE_MERCHANT_ACCOUNT = gql`
  mutation adminRevokeMerchantAccount($id: String) {
    adminRevokeMerchantAccount(id: $id) {
      id
    }
  }
`;

export const REVOKE_BUYER_ACCOUNT = gql`
  mutation adminRevokeBuyerAccount($id: String) {
    adminRevokeBuyerAccount(id: $id) {
      id
    }
  }
`;

export const UNLOCK_MERCHANT_ACCOUNT = gql`
  mutation adminUnlockMerchantAccount($id: String) {
    adminUnlockMerchantAccount(id: $id) {
      id
    }
  }
`;

export const UNLOCK_BUYER_ACCOUNT = gql`
  mutation adminUnlockBuyerAccount($id: String) {
    adminUnlockBuyerAccount(id: $id) {
      id
    }
  }
`;

export const ARCHIVE_PRODUCT = gql`
  mutation adminArchiveProduct($id: ID!) {
    adminArchiveProduct(id: $id) {
      id
    }
  }
`;

export const UPLOAD_IMAGE = gql`
  mutation uploadImage($file: string!, $name: string!) {
    uploadImage(file: $file, name: $name) {
      id
      uri
    }
  }
`;

export const SAVE_IMAGE = gql`
  mutation saveImage($url: String!, $publicId: String!) {
    saveImage(url: $url, publicId: $publicId) {
      id
      uri
    }
  }
`;

export const UPLOAD_IMAGES = gql`
  mutation uploadImages($files: [ImageUpload!]!) {
    uploadImages(files: $files) {
      id
      uri
    }
  }
`;

export const REMOVE_IMAGE = gql`
  mutation removeImage($id: ID) {
    removeImage(id: $id) {
      id
    }
  }
`;

export const CREATE_HAIR_TYPE = gql`
  mutation createHairType($title: String) {
    createHairType(title: $title) {
      id
    }
  }
`;

export const DELETE_HAIR_TYPE = gql`
  mutation deleteHairType($id: ID) {
    deleteHairType(id: $id) {
      id
    }
  }
`;

export const UPDATE_HAIR_TYPE = gql`
  mutation updateHairType($id: ID, $title: String) {
    updateHairType(id: $id, title: $title) {
      id
    }
  }
`;
export const DELETE_PRODUCT_REVIEW = gql`
  mutation deleteProductReview($where: ProductReviewWhereUniqueInput!) {
    deleteProductReview(where: $where) {
      id
    }
  }
`;

export const DELETE_COUPON = gql`
  mutation deleteCoupon($id: ID) {
    deleteCoupon(id: $id) {
      id
    }
  }
`;

export const UPDATE_COUPON = gql`
  mutation updateCoupon(
    $id: ID
    $code: String
    $discount: Float
    $status: CouponStatus
    $type: CouponType
    $merchantId: ID
    $productId: ID
    $expiry: DateTime
  ) {
    updateCoupon(
      id: $id
      code: $code
      discount: $discount
      status: $status
      type: $type
      merchantId: $merchantId
      productId: $productId
      expiry: $expiry
    ) {
      id
    }
  }
`;
export const ADD_COUPON = gql`
  mutation createCoupon(
    $code: String
    $discount: Float
    $status: CouponStatus
    $type: CouponType
    $merchantId: ID
    $productId: ID
    $expiry: DateTime
  ) {
    createCoupon(
      code: $code
      discount: $discount
      status: $status
      type: $type
      merchantId: $merchantId
      productId: $productId
      expiry: $expiry
    ) {
      id
    }
  }
`;

export const CREATE_CATEGORY = gql`
  mutation createCategory($title: String, $shortCode: String) {
    createCategory(title: $title, shortCode: $shortCode) {
      id
    }
  }
`;

export const DELETE_CATEGORY = gql`
  mutation deleteCategory($id: ID) {
    deleteCategory(id: $id) {
      id
    }
  }
`;

export const CREATE_CATEGORY_TYPE = gql`
  mutation createCategoryType(
    $parent: CategoryGroupWhereUniqueInput
    $category: CategoryWhereUniqueInput
    $children: [CategoryWhereUniqueInput!]
    $isMultiple: Boolean
  ) {
    createCategoryType(
      parent: $parent
      category: $category
      children: $children
      isMultiple: $isMultiple
    ) {
      id
    }
  }
`;

export const DELETE_CATEGORY_TYPE = gql`
  mutation deleteCategoryType($id: ID) {
    deleteCategoryType(id: $id) {
      id
    }
  }
`;
export const UPDATE_CATEGORY_TYPE = gql`
  mutation updateCategoryType(
    $parent: CategoryGroupWhereUniqueInput
    $category: CategoryWhereUniqueInput
    $children: [CategoryWhereUniqueInput!]
    $isMultiple: Boolean
    $id: ID
  ) {
    updateCategoryType(
      parent: $parent
      category: $category
      children: $children
      isMultiple: $isMultiple
      id: $id
    ) {
      id
      children {
        id
        title
      }
      category {
        id
        title
      }
    }
  }
`;
