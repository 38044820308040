import { useEffect } from 'react';
import {
  withNavigationViewController,
  viewReducerUtils,
  // @ts-ignore
} from '@atlaskit/navigation-next';

import LinkItem from './LinkItem';

export type ViewComponentProps = {
  getItemsFactory: (val?: any) => () => Array<any>;
  viewId: string;
  type: 'settings' | 'root';
  getAnalyticsAttributes?: any;
};

type Props = ViewComponentProps & {
  navigationViewController: any;
};

export type ViewData = Array<Record<string, any>>;

const getAnalyticsAttributes = (items: ViewData) => {
  const flattenedItems = viewReducerUtils.flattenItems(items);

  return {
    standardItemCount: flattenedItems.filter((i: { type: string }) => i.type === 'Item').length,
    groupCount: flattenedItems.filter((i: { type: string }) => i.type === 'Group').length,
    sectionCount: flattenedItems.filter((i: { type: string }) => i.type === 'Section').length,
    linkCount: flattenedItems.filter(
      (i: { type: ({ itemComponent: Component, to, ...props }: any) => JSX.Element }) =>
        i.type === LinkItem,
    ).length,
  };
};

function MenuRegistrarBase({
  navigationViewController,
  viewId,
  getItemsFactory,
  type,
  getAnalyticsAttributes: customGetAnalyticsAttributes,
}: Props) {
  useEffect(() => {
    if (!navigationViewController.views[viewId]) {
      navigationViewController.addView({
        getAnalyticsAttributes: customGetAnalyticsAttributes || getAnalyticsAttributes,
        getItems: getItemsFactory(),
        id: viewId,
        type,
      });
    }
  }, [navigationViewController, viewId, getItemsFactory, type, customGetAnalyticsAttributes]);
  return null;
}

const MenuRegistrar = withNavigationViewController(MenuRegistrarBase);

export default MenuRegistrar;
