import React from 'react';
// @ts-ignore
import { ErrorMessage } from '@atlaskit/form';
import { ApolloError } from 'apollo-boost';

import { itemHasValue } from '../utils/utils';
const GraphqlError = ({ error }: { error: ApolloError | undefined }) => {
  if (!itemHasValue(error)) return null;
  if (error && itemHasValue(error) && itemHasValue(error.graphQLErrors)) {
    return (
      <ErrorMessage>
        {error.graphQLErrors[0].message ||
          'An error occurred creating your account, check the input above and try again'}
      </ErrorMessage>
    );
  }
  return (
    <ErrorMessage>
      Seems there is a problem with the network connection. Check your connection (we are checking
      ours too).
    </ErrorMessage>
  );
};

GraphqlError.defaultProps = {
  error: {},
};

export default GraphqlError;
