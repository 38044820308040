import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import { Admin } from '../types';
import { SIGN_IN } from '../resolvers/mutations';
import { storeUserTokenInCookie } from '../utils/cookies';
import { useAuthDataContext } from './useAuthenticated';

interface TMutationData {
  adminSignIn: {
    token: string;
    admin: Admin;
  };
}

export default function useAuth() {
  let history = useHistory();
  const authenticated = useAuthDataContext();

  const [adminSignIn, { loading, error }] = useMutation<TMutationData>(SIGN_IN, {
    async update(_cache, { data }) {
      if (data && data.adminSignIn) {
        const userToken = {
          token: data.adminSignIn.token,
          role: 'ADMIN',
          email: data.adminSignIn.admin.email,
          name: data.adminSignIn.admin.name,
        };
        if (authenticated) {
          authenticated.onLogin(userToken);
        }
        storeUserTokenInCookie(JSON.stringify(userToken));
      }
    },
  });

  const handleFormSubmit = (email: string, password: string) => {
    adminSignIn({
      variables: {
        email,
        password,
      },
    }).then(() => history.push('/'));
  };

  return {
    handleFormSubmit,
    loading,
    error,
  };
}
