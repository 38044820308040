import React, { Component, ErrorInfo } from 'react';
import ErrorPage from './pages/error';
import { notifySentry } from './sentry';

export default class AppErrorBoundary extends Component {
  state = { hasError: false };

  static getDerivedStateFromError(): { hasError: boolean } {
    return { hasError: true };
  }

  componentDidCatch(error: Error | null, errorInfo: ErrorInfo): void {
    notifySentry(error, errorInfo);
  }

  componentDidUpdate(): void {
    if (this.state.hasError) {
      setTimeout(() => this.setState({ redirect: true }), 5000);
    }
  }

  render(): React.ReactNode {
    return this.state.hasError ? (
      <ErrorPage message="An unexpected error has occured." />
    ) : (
      this.props.children
    );
  }
}
