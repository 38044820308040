import ActivityIcon from '@atlaskit/icon/glyph/activity';
import AppAccessIcon from '@atlaskit/icon/glyph/app-access';
import CommentIcon from '@atlaskit/icon/glyph/comment';
import DashboardIcon from '@atlaskit/icon/glyph/dashboard';
import DepartmentIcon from '@atlaskit/icon/glyph/department';
import EmojiAddIcon from '@atlaskit/icon/glyph/emoji-add';
import LabelIcon from '@atlaskit/icon/glyph/label';
import LocationIcon from '@atlaskit/icon/glyph/location';
import PeopleIcon from '@atlaskit/icon/glyph/people';
// @ts-ignore
import { NavigationRendererItemType } from '@atlaskit/navigation-next';
import React from 'react';
import { ReactComponent as CTHLogo } from '../../../../assets/cth-logo-word.svg';
import LinkItem from '../../LinkItem';
import MenuRegistrar from '../../MenuRegistrar';

const getItems = (): NavigationRendererItemType<{
  type: 'LinkItem';
  id: string;
}>[] => [
  {
    id: 'root/index:header',
    items: [
      {
        type: 'Wordmark',
        wordmark: () => <CTHLogo />,
        id: 'cth-wordmark',
      },
    ],
    type: 'HeaderSection',
  },
  {
    id: 'root/index:menu',
    items: [
      {
        type: 'InlineComponent',
        component: LinkItem,
        id: 'dashboards',
        text: 'Dashboards',
        before: DashboardIcon,
        to: '/',
      },
      {
        before: DepartmentIcon,
        goTo: 'root/orders/index',
        id: 'orders',
        text: 'Orders',
        type: 'GoToItem',
      },
      {
        before: EmojiAddIcon,
        goTo: 'root/reviews/index',
        id: 'reviews',
        text: 'Reviews',
        type: 'GoToItem',
      },
      {
        before: LabelIcon,
        goTo: 'root/coupons/index',
        id: 'coupons',
        text: 'Coupons',
        type: 'GoToItem',
      },
      {
        before: ActivityIcon,
        goTo: 'root/products/index',
        id: 'products',
        text: 'Products',
        type: 'GoToItem',
      },
      {
        type: 'InlineComponent',
        component: LinkItem,
        id: 'buyers',
        text: 'Buyers',
        before: PeopleIcon,
        to: '/buyers',
      },
      {
        before: AppAccessIcon,
        goTo: 'root/merchants/index',
        id: 'merchants',
        text: 'Merchants',
        type: 'GoToItem',
      },
      {
        before: LocationIcon,
        goTo: 'root/salons/index',
        id: 'salons-items',
        text: 'Salons',
        type: 'GoToItem',
      },
      {
        before: CommentIcon,
        goTo: 'root/testimonials/index',
        id: 'testimonial-items',
        text: 'Testimonial',
        type: 'GoToItem',
      },
    ],
    nestedGroupKey: 'menu',
    parentId: null,
    type: 'MenuSection',
  },
];

const HomeView = () => (
  <MenuRegistrar getItemsFactory={() => getItems} type="product" viewId="root/index" />
);

const HomeMenu = () => <HomeView />;

export default HomeMenu;
