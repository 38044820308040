import gql from 'graphql-tag';

const ORDER = gql`
  fragment order on Order {
    id
    orderNumber
    price {
      total
      delivery
      subtotal
      coupon {
        id
        discount
      }
      id
    }
    buyer {
      id
      firstName
      lastName
      image {
        id
        uri
      }
    }
    merchant {
      id
      business {
        id
        shopName
        displayImage {
          id
          uri
        }
      }
    }
    items {
      id
      quantity
      product {
        id
        price
        title
      }
    }
    status
    createdAt
  }
`;
const ORDER_ISSUES = gql`
  fragment orderIssues on OrderIssue {
    id
    topic
    buyer {
      id
      image {
        id
        uri
      }
      email
      firstName
      lastName
    }
    order {
      id
      merchant {
        id
        business {
          shopName
          displayImage {
            id
            uri
          }
        }
        lastName
        firstName
      }
    }
    productName
    status
    createdAt
  }
`;

export const GET_ALL_ORDERS = gql`
  query GetAllOrders(
    $where: OrderWhereInput
    $orderBy: OrderOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    orders(
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      ...order
    }
  }
  ${ORDER}
`;

export const GET_ORDER = gql`
  query GetOrder($where: OrderWhereInput) {
    orders(where: $where) {
      ...order
      items {
        id
        colour
        length
        price
        quantity
        product {
          id
          price
          title
          productAttribute {
            id
            closure {
              id
              parting
              length
            }
            delivery {
              id
              cost
              freePnP
            }
          }
          variations {
            id
            price
          }
        }
        order {
          id
          price {
            id
            coupon {
              id
              discount
            }
            delivery
            subtotal
            total
          }
        }
        variationId
      }
      delivery {
        id
        address
        phoneNumber
      }
      estimatedShippingDate
      shippingDate
      payment {
        id
        amount
        status
      }
      price {
        id
        coupon {
          id
          discount
        }
        delivery
        subtotal
        total
      }
    }
  }
  ${ORDER}
`;

export const GET_ALL_ORDERS_ISSUES = gql`
  query GetAllOrdersIssues(
    $where: OrderIssueWhereInput
    $orderBy: OrderIssueOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    orderIssues(
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      ...orderIssues
    }
  }
  ${ORDER_ISSUES}
`;

export const GET_ORDER_ISSUE = gql`
  query GetOrderIssue($id: ID) {
    orderIssue(id: $id) {
      ...orderIssues
      description
      image {
        id
        uri
      }
      createdAt
    }
  }
  ${ORDER_ISSUES}
`;
