import Avatar, { AvatarItem } from '@atlaskit/avatar';
import DynamicTable from '@atlaskit/dynamic-table';
import EmptyState from '@atlaskit/empty-state';
import { pathOr, isEmpty } from 'ramda';
import React, { FC } from 'react';
import { useHistory, Link } from 'react-router-dom';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import TableCell from '../../components/TableCell';
import { TData, TRevenueData } from '../../hooks/useDashboard';
import { Buyer, Merchant, OrderIssue, ProductReview } from '../../types';
import { appendCurrency, capitalize } from '../../utils/utils';

interface Props {
  data: TData | undefined;
}

const CustomizedXAxisTick: FC<any> = (props) => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        fontSize="11"
        x={0}
        y={0}
        dy={10}
        textAnchor="end"
        fill="#946669"
        transform="rotate(-30)"
      >
        {payload.value}
      </text>
    </g>
  );
};
const CustomizedYAxisTick: FC<any> = (props) => {
  const { x, y, payload } = props;
  console.log('payload', payload.value);
  return (
    <g transform={`translate(${x},${y})`}>
      <text fontSize="11" x={0} y={0} dy={10} textAnchor="end" fill="#555">
        {appendCurrency(parseInt(payload.value, 10))}
      </text>
    </g>
  );
};

export const RevenueLineChart = ({ data }: { data: Array<TRevenueData> | undefined }) => {
  return (
    <ResponsiveContainer width="100%" height={500}>
      <LineChart height={250} data={data} margin={{ top: 50, right: 10, left: 10, bottom: 30 }}>
        <Legend
          align="right"
          verticalAlign="middle"
          iconSize={20}
          wrapperStyle={{ top: '0', marginTop: '20px' }}
        />
        <XAxis dataKey="date" tick={<CustomizedXAxisTick />} />
        <YAxis allowDecimals={false} dataKey="revenue" tick={<CustomizedYAxisTick />} />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip />
        <Line
          type="linear"
          dataKey="revenue"
          strokeOpacity={0.5}
          strokeWidth={2}
          stroke="#f16cc0"
          activeDot={{ r: 5 }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export const MerchantTable = ({ data }: Props) => {
  const history = useHistory();
  return (
    <DynamicTable
      // @ts-ignore
      head={{
        cells: [
          {
            key: 'sn',
            content: <TableCell>S/N</TableCell>,
            width: 7,
          },
          {
            key: 'shopname',
            shouldTruncate: false,
            content: <TableCell>Business Name</TableCell>,
          },
          {
            key: 'description',
            shouldTruncate: true,
            content: <TableCell>Description</TableCell>,
          },
        ],
      }}
      rows={pathOr<Merchant[]>([], ['merchants'], data).map((merchant, idx) => ({
        cells: [
          {
            key: 'sn',
            content: <TableCell>{idx + 1}</TableCell>,
          },
          {
            key: 'shopname',
            content: (
              <TableCell>
                <AvatarItem
                  avatar={
                    <Avatar
                      src={pathOr(
                        require('../../assets/product-placeholder.png'),
                        ['business', 'displayImage', 'uri'],
                        merchant,
                      )}
                    />
                  }
                  primaryText={`${capitalize(pathOr('-', ['business', 'shopName'], merchant))}`}
                  onClick={() => history.push(`/merchants/profile/${merchant.id}`)}
                  secondaryText={merchant.email}
                />
              </TableCell>
            ),
          },
          {
            key: 'description',
            content: <TableCell>{pathOr('-', ['business', 'description'], merchant)}</TableCell>,
          },
        ],
        key: merchant.id,
      }))}
      emptyView={
        <EmptyState
          {...emptyProps('No merchants added', 'No merchant has been added to the platform')}
        />
      }
    />
  );
};
export const BuyerTable = ({ data }: { data: TData | undefined }) => {
  const history = useHistory();
  return (
    <DynamicTable
      // @ts-ignore
      head={{
        cells: [
          {
            key: 'sn',
            content: <TableCell>S/N</TableCell>,
            width: 7,
          },
          {
            key: 'name',
            shouldTruncate: false,
            content: <TableCell>Name</TableCell>,
          },
          {
            key: 'provider',
            shouldTruncate: false,
            content: <TableCell>Account Type</TableCell>,
          },
        ],
      }}
      rows={pathOr<Buyer[]>([], ['buyers'], data).map((buyer, idx) => ({
        cells: [
          {
            key: 'sn',
            content: <TableCell>{idx + 1}</TableCell>,
          },
          {
            key: 'name',
            content: (
              <TableCell>
                <AvatarItem
                  avatar={
                    <Avatar
                      src={pathOr(
                        require('../../assets/product-placeholder.png'),
                        ['image', 'uri'],
                        buyer,
                      )}
                    />
                  }
                  primaryText={`${capitalize(buyer.firstName)} ${capitalize(buyer.lastName)}`}
                  onClick={() => history.push(`/buyers/${buyer.id}`)}
                  secondaryText={buyer.email}
                />
              </TableCell>
            ),
          },
          {
            key: 'provider',
            content: <TableCell>{buyer.provider}</TableCell>,
          },
        ],
        key: buyer.id,
      }))}
      emptyView={
        <EmptyState
          {...emptyProps('No buyers', 'There is no registered buyers on the platform this time')}
        />
      }
    />
  );
};

export const ReviewTable = ({ data }: { data: TData | undefined }) => {
  const history = useHistory();
  return (
    <>
      <DynamicTable
        // @ts-ignore
        head={{
          cells: [
            {
              key: 'sn',
              content: <TableCell>S/N</TableCell>,
              width: 7,
            },
            {
              key: 'buyer',
              shouldTruncate: false,
              content: <TableCell>Buyer</TableCell>,
            },
            {
              key: 'product',
              shouldTruncate: true,
              content: <TableCell>Product</TableCell>,
            },
            {
              key: 'comment',
              shouldTruncate: true,
              content: <TableCell>Comment</TableCell>,
            },
          ],
        }}
        rows={pathOr<ProductReview[]>([], ['productReviews'], data).map((review, idx) => ({
          cells: [
            {
              key: 'sn',
              content: <TableCell>{idx + 1}</TableCell>,
            },
            {
              key: 'buyer',
              content: (
                <TableCell>
                  <AvatarItem
                    avatar={
                      <Avatar
                        src={pathOr(
                          require('../../assets/product-placeholder.png'),
                          ['buyer', 'image', 'uri'],
                          review,
                        )}
                      />
                    }
                    primaryText={`${capitalize(review.buyer.firstName)} ${capitalize(
                      review.buyer.lastName,
                    )}`}
                    onClick={() => history.push(`/buyers/${review.buyer.id}`)}
                    secondaryText={review.buyer.email || ''}
                  />
                </TableCell>
              ),
            },
            {
              key: 'product',
              content: (
                <TableCell>
                  <AvatarItem
                    avatar={
                      <Avatar
                        src={
                          !isEmpty(review.product.productAttribute.images)
                            ? review.product.productAttribute.images[0].uri
                            : require('../../assets/product-placeholder.png')
                        }
                      />
                    }
                    primaryText={capitalize(review.product.title)}
                    onClick={() => history.push(`/products/product-${review.product.slug}`)}
                    secondaryText={review.product.productCode || ''}
                  />
                </TableCell>
              ),
            },
            {
              key: 'comment',
              content: <TableCell>{review.comment}</TableCell>,
            },
          ],
          key: review.id,
        }))}
        emptyView={<EmptyState {...emptyProps('No reviews', 'No reviews have been added.')} />}
      />
    </>
  );
};

export const IssuesTable = ({ data }: { data: TData | undefined }) => {
  const history = useHistory();
  return (
    <DynamicTable
      // @ts-ignore
      head={{
        cells: [
          {
            key: 'sn',
            content: <TableCell>S/N</TableCell>,
            width: 7,
          },
          {
            key: 'buyer',
            shouldTruncate: false,
            content: <TableCell>Buyer</TableCell>,
          },
          {
            key: 'productName',
            shouldTruncate: false,
            content: <TableCell>Product</TableCell>,
          },
          {
            key: 'topic',
            shouldTruncate: false,
            content: <TableCell>Topic</TableCell>,
          },
        ],
      }}
      rows={pathOr<OrderIssue[]>([], ['orderIssues'], data).map((orderIssue, idx) => ({
        cells: [
          {
            key: 'sn',
            content: <TableCell>{idx + 1}</TableCell>,
          },
          {
            key: 'buyer',
            content: (
              <TableCell>
                <AvatarItem
                  avatar={
                    <Avatar
                      src={pathOr(
                        require('../../assets/product-placeholder.png'),
                        ['buyer', 'image', 'uri'],
                        orderIssue,
                      )}
                    />
                  }
                  primaryText={capitalize(
                    `${orderIssue.buyer.firstName} ${orderIssue.buyer.lastName}`,
                  )}
                  onClick={() => history.push(`/buyers/${orderIssue.buyer.id}`)}
                  secondaryText={capitalize(orderIssue.buyer.email)}
                />
              </TableCell>
            ),
          },
          {
            key: 'productName',
            content: <TableCell>{orderIssue.productName}</TableCell>,
          },
          {
            key: 'topic',
            content: (
              <TableCell>
                <Link to={`/orders/issues/${orderIssue.id}`}>{orderIssue.topic}</Link>
              </TableCell>
            ),
          },
        ],
        key: orderIssue.id,
      }))}
      emptyView={<EmptyState {...emptyProps('No order issues.', 'The platform has no order(s)')} />}
    />
  );
};

export const emptyProps = (header: string, description: string) => ({
  header,
  description,
});
