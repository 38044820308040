import React from 'react';
// @ts-ignore
import { NavigationRendererItemType } from '@atlaskit/navigation-next';
import AddItemIcon from '@atlaskit/icon/glyph/add-item';
import ListIcon from '@atlaskit/icon/glyph/list';
import MenuRegistrar from '../../MenuRegistrar';

const getItems = (): NavigationRendererItemType<{
  type: 'LinkItem';
  id: string;
}>[] => [
  {
    id: 'root/testimonials/index:header',
    items: [
      {
        type: 'Wordmark',
        wordmark: () => <p>Testimonials</p>,
        id: 'cth-wordmark',
      },
      {
        id: 'back-button',
        items: [
          {
            type: 'BackItem',
            goTo: 'root/index',
            id: 'back',
            text: 'Back to home',
          },
        ],
        type: 'Group',
      },
    ],
    type: 'HeaderSection',
  },
  {
    id: 'root/testimonials/index:menu',
    nestedGroupKey: 'menu',
    items: [
      {
        before: ListIcon,
        id: 'all-testiomonials',
        text: 'All Testimonial',
        to: '/testimonials',
        type: 'LinkItem',
      },
      {
        before: AddItemIcon,
        id: 'add-testimonial',
        text: 'Create Testimonial',
        to: '/testimonials/add',
        type: 'LinkItem',
      },
    ],
    type: 'MenuSection',
  },
];

const TestimonialsView = () => (
  <MenuRegistrar
    getItemsFactory={() => getItems}
    type="container"
    viewId="root/testimonials/index"
  />
);

const TestimonialMenu = () => <TestimonialsView />;

export default TestimonialMenu;
