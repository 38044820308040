import React from 'react';
import '@atlaskit/css-reset';
import { ApolloProvider } from '@apollo/react-hooks';
import apolloClient from './apolloClient';
import MainApp from './routes';

const App = () => (
  // @ts-ignore
  <ApolloProvider client={apolloClient}>
    <MainApp />
  </ApolloProvider>
);

export default App;
