import {
  LayoutManagerWithViewController,
  modeGenerator,
  NavigationProvider,
  ThemeProvider,
  //@ts-ignore
} from '@atlaskit/navigation-next';
import { colors } from '@atlaskit/theme';
import React, { useState, lazy, Suspense } from 'react';
import Notifications from 'react-notify-toast';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import {
  GlobalNavigation,
  HomeMenu,
  LinkItem,
  MerchantsMenu,
  OrdersMenu,
  ProductsMenu,
  ReviewsMenu,
  SalonsMenu,
  TestimonialsMenu,
  CouponsMenu,
} from '../components/navigations';
import AuthDataProvider from '../hooks/useAuthenticated';
import AuthPage from '../pages/auth';
import HomePage from '../pages/home/home';
import AuthedRoute from './authedRoute';
import UnAuthedRouteProps from './unAuthorisedRoute';
import Spinner from '@atlaskit/spinner';
import AppErrorBoundary from '../AppErrorBoundary';
import PageNotFound from '../pages/page-not-found';

const BuyersRoute = lazy(() => import('./buyers'));
const MerchantRoutes = lazy(() => import('./merchant'));
const OrderRoutes = lazy(() => import('./order'));
const ProductRoutes = lazy(() => import('./product'));
const SalonRoutes = lazy(() => import('./salon'));
const SettingRoutes = lazy(() => import('./settings'));
const TestimonialRoutes = lazy(() => import('./testimonial'));
const CouponRoutes = lazy(() => import('./coupon'));

const PageContainer = styled.div`
  padding: 40px;
`;

const customThemeMode = modeGenerator({
  product: {
    text: colors.N0,
    background: '#f16cc0',
  },
});

function MainRouter() {
  const [isFlyoutAvailable] = useState(false);
  const [isAlternateFlyoutBehaviourEnabled] = useState(false);
  const [isFullWidthFlyoutEnabled] = useState(false);

  return (
    <Router>
      <AppErrorBoundary>
        <AuthDataProvider>
          <Switch>
            <UnAuthedRouteProps exact path="/auth" component={AuthPage} />
            <NavigationProvider>
              <ThemeProvider theme={(theme: any) => ({ ...theme, mode: customThemeMode })}>
                <LayoutManagerWithViewController
                  customComponents={{ LinkItem }}
                  experimental_flyoutOnHover={isFlyoutAvailable}
                  experimental_alternateFlyoutBehaviour={isAlternateFlyoutBehaviourEnabled}
                  experimental_fullWidthFlyout={isFullWidthFlyoutEnabled}
                  globalNavigation={GlobalNavigation}
                >
                  <PageContainer>
                    <Notifications />
                    <HomeMenu />
                    <AuthedRoute strict exact path="/" component={HomePage} />
                    <Suspense fallback={<Spinner size="xsmall" />}>
                      <TestimonialsMenu />
                      <SalonsMenu />
                      <OrdersMenu />
                      <ProductsMenu />
                      <ReviewsMenu />
                      <MerchantsMenu />
                      <CouponsMenu />

                      <SettingRoutes />
                      <SalonRoutes />
                      <TestimonialRoutes />
                      <OrderRoutes />
                      <ProductRoutes />
                      <BuyersRoute />
                      <MerchantRoutes />
                      <CouponRoutes />
                    </Suspense>
                  </PageContainer>
                </LayoutManagerWithViewController>
              </ThemeProvider>
            </NavigationProvider>
            <Route component={PageNotFound} />
          </Switch>
        </AuthDataProvider>
      </AppErrorBoundary>
    </Router>
  );
}

export default MainRouter;
