import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { RouteProps, RouteComponentProps } from 'react-router-dom';
import { useAuthDataContext } from '../hooks/useAuthenticated';
import { itemHasValue } from '../utils/utils';

interface AuthedRouteProps extends RouteProps {
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
}

function AuthedRouteProvider(props: AuthedRouteProps) {
  const authenticated = useAuthDataContext();
  const { component: Children, ...rest } = props;
  if (authenticated && itemHasValue(authenticated.token)) {
    return <Route {...rest} render={(props) => <Children {...props} />} />;
  }
  return (
    <Redirect
      to={{
        pathname: '/auth',
      }}
    />
  );
}

export default AuthedRouteProvider;
