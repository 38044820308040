import React from 'react';
// @ts-ignore
import { NavigationRendererItemType } from '@atlaskit/navigation-next';
import AddItemIcon from '@atlaskit/icon/glyph/add-item';
import ListIcon from '@atlaskit/icon/glyph/list';
import QuoteIcon from '@atlaskit/icon/glyph/quote';
import MenuRegistrar from '../../MenuRegistrar';

const getItems = (): NavigationRendererItemType<{
  type: 'LinkItem';
  id: string;
}>[] => [
  {
    id: 'root/salons/index:header',
    items: [
      {
        type: 'Wordmark',
        wordmark: () => <p>Salons</p>,
        id: 'cth-wordmark',
      },
      {
        id: 'back-button',
        items: [
          {
            type: 'BackItem',
            goTo: 'root/index',
            id: 'back',
            text: 'Back to home',
          },
        ],
        type: 'Group',
      },
    ],
    type: 'HeaderSection',
  },
  {
    id: 'root/salons/index:menu',
    nestedGroupKey: 'menu',
    items: [
      {
        before: ListIcon,
        id: 'all-salons',
        text: 'All Salons',
        to: '/salons',
        type: 'LinkItem',
      },
      {
        before: QuoteIcon,
        id: 'salon-reviews',
        text: 'Salon Reviews',
        to: '/salons/reviews',
        type: 'LinkItem',
      },
      {
        before: AddItemIcon,
        id: 'create-salon',
        text: 'Create Salon',
        to: '/salons/add',
        type: 'LinkItem',
      },
    ],
    type: 'MenuSection',
  },
];

const SalonsView = () => (
  <MenuRegistrar getItemsFactory={() => getItems} type="container" viewId="root/salons/index" />
);

const SalonsMenu = () => <SalonsView />;

export default SalonsMenu;
