import React from 'react';
// @ts-ignore
import { NavigationRendererItemType } from '@atlaskit/navigation-next';
import AddItemIcon from '@atlaskit/icon/glyph/add-item';
import ListIcon from '@atlaskit/icon/glyph/list';
import MenuRegistrar from '../../MenuRegistrar';

const getItems = (): NavigationRendererItemType<{
  type: 'LinkItem';
  id: string;
}>[] => [
  {
    id: 'root/coupons/index:header',
    items: [
      {
        type: 'Wordmark',
        wordmark: () => <p>Coupons</p>,
        id: 'cth-wordmark',
      },
      {
        id: 'back-button',
        items: [
          {
            type: 'BackItem',
            goTo: 'root/index',
            id: 'back',
            text: 'Back to home',
          },
        ],
        type: 'Group',
      },
    ],
    type: 'HeaderSection',
  },
  {
    id: 'root/coupons/index:menu',
    nestedGroupKey: 'menu',
    items: [
      {
        before: ListIcon,
        id: 'all-coupons',
        text: 'All Coupons',
        to: '/coupons',
        type: 'LinkItem',
      },
      {
        before: AddItemIcon,
        id: 'create-coupon',
        text: 'Create Coupon',
        to: '/coupons/add',
        type: 'LinkItem',
      },
    ],
    type: 'MenuSection',
  },
];

const CouponsView = () => (
  <MenuRegistrar getItemsFactory={() => getItems} type="container" viewId="root/coupons/index" />
);

const CouponsMenu = () => <CouponsView />;

export default CouponsMenu;
