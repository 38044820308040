import { useQuery } from '@apollo/react-hooks';
import { useState } from 'react';
import { GET_ADMIN_REVENUE, GET_DASHBOARD_DATA } from '../resolvers/queries';
import { Buyer, Merchant, Order, OrderIssue, ProductReview } from '../types';
import { timeFrames } from '../utils/constants';

export interface TAnalyticsData {
  totalBuyers: Buyer[];
  totalMerchants: Merchant[];
  totalOrders: Order[];
}
export interface TData {
  orderIssues: OrderIssue[];
  productReviews: ProductReview[];
  merchants: Merchant[];
  buyers: Buyer[];
  adminAnalyticsData: TAnalyticsData;
}

export interface TRevenueData {
  date: string;
  revenue: number;
}

const useDashboard = () => {
  const { data, loading, error } = useQuery<TData>(GET_DASHBOARD_DATA);
  const [timeFrame, setTimeFrame] = useState(timeFrames[0].value);
  const { data: revenueData } = useQuery<{ adminRevenue: TRevenueData[] }>(GET_ADMIN_REVENUE, {
    variables: {
      filterBy: timeFrame,
    },
  });

  const handleTimeFrameChange = (evt: any) => {
    if (evt) {
      setTimeFrame(evt.value.toString());
    }
  };

  return {
    error,
    loading,
    data,
    revenueData,
    timeFrame,
    setTimeFrame: handleTimeFrameChange,
  };
};

export default useDashboard;
